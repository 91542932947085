'use client';

import { useState } from 'react';
import './styles.css';

interface ImageSwitcherProps {
    data: {
        imageA: {
            url: string;
            alt: string;
            width: number;
            height: number;
        };
        imageB: {
            url: string;
            alt: string;
            width: number;
            height: number;
        };
    };
}

export default function ImageSwitcher({ data }: ImageSwitcherProps) {
    // console.dir(data);
    const [hovered, setHovered] = useState(false);
    const { imageA, imageB } = data;
    const assetsBaseUrl = process.env.NEXT_PUBLIC_STRAPI_URL;

    return (
        <div className="image-container">
            <img
                //prettier-ignore
                src={`${assetsBaseUrl}${imageA.url}`}
                alt={imageA.alt}
                width={imageA.width}
                height={imageA.height}
                className={`image ${hovered ? 'hidden-image' : 'visible-image'}`}
                onMouseEnter={() => setHovered(true)}
            />
            <img
                //prettier-ignore
                src={`${assetsBaseUrl}${imageB.url}`}
                alt={imageB.alt}
                width={imageB.width}
                height={imageB.height}
                className={`image ${hovered ? 'visible-image' : 'hidden-image'}`}
                onMouseLeave={() => setHovered(false)}
            />
        </div>
    );
}
