import(/* webpackMode: "eager" */ "/next.js/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/next.js/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_components/Form/PopForm.tsx");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_components/Skeleton/ImageSkeleton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/_home/components/image-switcher/ImageSwitcher.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/next.js/src/app/_home/components/Loading.tsx");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/1-hero.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/PopForm-2.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/2-intro.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/3-customlist.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/4-brief.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/5-sample.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/PopForm.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/6-cert.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/7-testimonials.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/8-Benefits.module.css");
;
import(/* webpackMode: "eager" */ "/next.js/src/app/_home/components/styles/9-faq.module.css");
